const Variant10 = () => (
  <svg className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1405 420" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1174.01 358.062V61.0625H1244.81V72.6825C1256.36 64.7234 1278.05 57.0625 1300.16 57.0625C1331.8 57.0625 1358 67.6485 1376.25 87.4384C1394.43 107.161 1405.01 135.265 1405.01 169.063C1405.01 202.86 1394.43 230.964 1376.25 250.687C1358 270.477 1331.8 281.062 1300.16 281.062C1279.05 281.062 1260.36 276.402 1244.81 267.442V358.062H1174.01ZM1260.69 130.197C1267.47 122.587 1277.69 118.062 1292.14 118.062C1306.59 118.062 1316.72 122.582 1323.43 130.167C1330.48 138.136 1336.01 150.873 1336.01 169.308C1336.01 187.569 1330.45 200.17 1323.38 208.059C1316.63 215.589 1306.47 220.062 1292.14 220.062C1277.69 220.062 1267.45 215.535 1260.67 207.964C1253.7 200.189 1249.01 187.685 1249.01 169.308C1249.01 150.654 1253.71 138.024 1260.69 130.197Z" />
    <path d="M145.102 124.523C164.341 124.523 178.28 131.506 185.418 138.12C191.275 143.547 198 152.547 203 173.055H276.186C273.206 154.989 266.559 138.003 263.507 131.583C259.634 123.342 253.446 112.354 244.16 101.342C225.453 79.1571 194.264 57.0547 145.102 57.0547C60.6939 57.0547 0 121.88 0 208.151C0 208.285 0.000140467 208.42 0.000420868 208.555C0.000140467 208.689 0 208.824 0 208.959C0 295.229 60.6939 360.055 145.102 360.055C194.264 360.055 225.453 337.952 244.16 315.767C253.446 304.755 259.634 293.767 263.507 285.527C265.448 281.399 266.819 277.935 267.716 275.468C268.165 274.234 268.495 273.247 268.719 272.549C268.831 272.199 268.917 271.921 268.978 271.721C269.008 271.62 269.032 271.539 269.05 271.478L269.072 271.401L269.08 271.374L269.083 271.363L269.085 271.354L272 261.075H197.072L195.062 266.12L195.061 266.124L195.058 266.13C195.051 266.148 195.035 266.184 195.012 266.238C194.953 266.369 194.846 266.604 194.686 266.927C194.365 267.575 193.835 268.571 193.066 269.801C191.523 272.268 189.051 275.623 185.418 278.99C178.28 285.603 164.341 292.587 145.102 292.587C120.993 292.587 103.404 284.029 90.9718 269.511C78.4604 254.9 70.8487 233.833 70.8487 208.555C70.8487 183.277 78.4604 162.21 90.9718 147.598C103.404 133.08 120.993 124.523 145.102 124.523Z" />
    <path d="M291 209.047L142 209.047L142 265.425H234.622V358.047H291L291 209.047Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M943 90.1007C943 99.151 941.27 107.797 938.123 115.727L900 229.601C900.002 274.382 913.461 307.279 936.023 328.947C958.509 350.541 989.041 360.026 1021.4 360.026C1053.76 360.026 1084.11 350.538 1106.41 328.92C1128.76 307.237 1142 274.34 1142 229.59V61.0261L1070.28 61.0261V225.576C1070.28 247.563 1065.82 263.95 1057.85 274.657C1050.16 284.978 1038.49 290.995 1021.4 290.995C1004.3 290.995 992.393 284.964 984.503 274.592C976.351 263.875 971.719 247.499 971.719 225.576V61.0261H936.645C940.724 69.8717 943 79.7205 943 90.1007Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M600.212 247.814C583.532 314.347 525.465 360.005 460.905 360.005C381.925 360.005 316 295.741 316 208.504C316 121.283 381.91 57.0046 460.5 57.0046C505.443 57.0046 547.398 79.1382 574.396 115.053C557.176 127.703 546 148.097 546 171.101C546 194.374 557.439 214.975 575.002 227.589L575 227.601L600.212 247.814ZM409.254 267.813C422.178 282.019 439.611 290.517 460.905 290.517C482.193 290.517 499.514 282.024 512.334 267.834C525.215 253.576 532.964 233.076 532.964 208.504C532.964 183.959 525.13 163.463 512.15 149.196C499.226 134.99 481.794 126.493 460.5 126.493C439.428 126.493 422.096 134.976 409.213 149.186C396.275 163.456 388.441 183.959 388.441 208.504C388.441 233.05 396.274 253.546 409.254 267.813Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M808.248 169.346C808.162 169.415 808.077 169.485 807.991 169.556C807.924 169.61 807.857 169.665 807.791 169.72C807.857 169.665 807.924 169.61 807.991 169.555C808.077 169.485 808.162 169.415 808.248 169.346ZM604.611 138.925L863.696 57.2139C863.743 57.1973 863.792 57.1814 863.84 57.1661C871.832 54.6531 880.477 55.1787 888.103 58.6444C895.736 62.11 901.809 68.2662 905.164 75.9436C908.518 83.6201 908.912 92.2594 906.268 100.206L906.265 100.213L829.013 331.17L829.013 331.172C825.404 341.951 816.624 350.203 805.641 353.147C802.795 353.964 799.851 354.432 796.869 354.523C796.82 354.524 796.771 354.525 796.722 354.525C788.462 354.533 780.483 351.517 774.288 346.064L725.273 302.934C721.139 299.467 718.573 294.485 718.166 289.096C717.756 283.677 719.576 278.342 723.172 274.295C726.775 270.242 731.87 267.821 737.297 267.593C742.692 267.36 747.941 269.319 751.864 273.002L794.062 310.139L864.628 98.7549L627.306 173.322L687.877 222.102L785.351 142.96C790.723 138.323 798.161 136.886 804.88 139.183L804.888 139.185C811.625 141.501 816.639 147.233 818.011 154.233C819.381 161.227 816.917 168.431 811.529 173.118C811.484 173.157 811.438 173.196 811.391 173.234C811.363 173.257 811.32 173.292 811.267 173.336C811.072 173.496 810.734 173.774 810.425 174.008L710.665 255.006L710.638 255.027C708.816 256.487 707.769 258.705 707.799 261.03C707.799 261.052 707.799 261.073 707.799 261.095V344.725C707.799 351.83 703.995 358.393 697.845 361.94C691.693 365.487 684.107 365.487 677.955 361.941C671.792 358.393 667.998 351.826 667.998 344.725V260.974L667.998 260.955C668.002 259.818 668.046 258.687 668.13 257.558L593.494 197.446L593.471 197.428C587.14 192.266 582.88 184.989 581.491 176.932C580.102 168.873 581.685 160.593 585.924 153.611C590.168 146.622 596.804 141.409 604.599 138.929L604.611 138.925Z" />
  </svg>
)

export default Variant10