import React, { forwardRef, useCallback, useEffect, useRef } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import gsap from 'gsap'

import useTranslation from '@hooks/useTranslation'
import Container from '@components/ui/Container'
import Logo from '@components/icons/Logo'
import ArrowButton from '@components/icons/ArrowButton'
import Linkedin from '@components/icons/Linkedin'
import Facebook from '@components/icons/Facebook'
import Twitter from '@components/icons/Twitter'

import { Network, SocialIcons, MenuItem } from '../../types'

interface HeaderProps {
  theme: 'primary' | 'black' | 'white',
  appIsReady: boolean
}

const Header = forwardRef<HTMLDivElement, HeaderProps>(({ theme, appIsReady }, ref) => {
  const { t, o } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const panel = useRef<HTMLDivElement>(null)
  const pathname = usePathname()
  
  const socialIcons = {
    linkedin: <Linkedin />,
    facebook: <Facebook />,
    twitter: <Twitter />,
  } as SocialIcons

  const menuItems = o('header.items') as MenuItem[]
  const networks = o('networks') as Network[]

  const handleScrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleMenuToggle = useCallback(() => {
    setOpen((prev) => !prev)
    document.body.classList.toggle('overflow-hidden')

    if (panel.current) {
      gsap.to(panel.current, {
        duration: 0.5,
        display: open ? 'none' : 'flex',
        autoAlpha: open ? 0 : 1,
        y: open ? 100 : 0,
        ease: 'power4.out',
      })
    }

  }, [panel, open])

  useEffect(() => {
    if (!appIsReady) return 

    // @ts-ignore
    gsap.to(ref.current, {
      duration: 0.5,
      autoAlpha: 1,
      y: 0,
      delay: 0.5,
    })
  }, [ref, appIsReady])

  return (
    <header
      ref={ref}
      data-theme={theme}
      data-open={open}
      className="group/header opacity-0 translate-y-30 fixed z-50 top-30 inset-x-0 py-20 transition-colors lg:top-0 lg:py-30 data-[theme=white]:text-black data-[theme=black]:text-white data-[theme=primary]:text-white data-[open=true]:!text-white">
      <Container>
        <div className="flex justify-between items-center">
          <div onClick={handleScrollToTop} className="relative z-20 w-94 cursor-pointer lg:w-104">
            <Link href="/">
              <Logo stopOnScroll />
            </Link>
            <h1 className="sr-only">{t('seo.title')}</h1>
          </div>

          <button onClick={handleMenuToggle} className="relative z-20 md:hidden">
            <span className="sr-only">{t('header.toggle')}</span>
            <i className="relative block w-26 h-20">
              <span className="absolute top-0 left-0 w-26 h-3 origin-center group-data-[theme=white]/header:bg-black group-data-[theme=black]/header:bg-white group-data-[theme=primary]/header:bg-white group-data-[open=true]/header:bg-white group-data-[open=true]/header:animate-menu-bar-top" />
              <span className="absolute bottom-0 left-0 w-26 h-3 origin-center group-data-[theme=white]/header:bg-black group-data-[theme=black]/header:bg-white group-data-[theme=primary]/header:bg-white group-data-[open=true]/header:bg-white group-data-[open=true]/header:animate-menu-bar-bottom" />
            </i>
          </button>

          <div ref={panel} className="hidden h-screen opacity-0 transform translate-y-100 fixed z-10 inset-x-0 -top-30 bg-primary/90 backdrop-blur flex-col justify-end px-25 py-40 md:top-0 md:h-auto md:backdrop-blur-none md:!block md:p-0 md:relative md:bg-transparent md:!translate-y-0 md:!opacity-100 md:!visible">
            <ul className="flex flex-col gap-25 md:flex-row md:gap-36">
              {menuItems.map((item) => (
                <li key={item.label}>
                  {item.target === '_blank' ? (
                    <a href={item.href} target="_blank" rel="noreferrer" className="group inline-flex items-center text-30-fix font-bold md:text-18">
                      <span>{item.label}</span>
                      <i className="block w-27 h-27 ml-15 transform transition-transform md:w-16 md:h-16 md:ml-10 -rotate-90 group-hover:rotate-0">
                        <ArrowButton />
                      </i>
                    </a>
                  ) : (
                    (item.href === pathname) ? (
                        <Link href={item.href} className="relative text-30-fix font-bold after:absolute after:inset-x-0 after:-bottom-5 after:h-2 after:bg-white after:scale-x-100 after:origin-bottom-right md:text-18 group-data-[theme=white]/header:md:after:bg-black group-data-[theme=black]/header:md:after:bg-white group-data-[theme=primary]/header:md:after:bg-white">
                        {item.label}
                      </Link>
                    ) : (
                          <Link href={item.href} className="relative text-30-fix font-bold after:absolute after:inset-x-0 after:-bottom-5 after:h-2 after:bg-white after:scale-x-0 after:origin-bottom-right after:transition-transform md:text-18 hover:after:scale-x-100 hover:after:origin-bottom-left group-data-[theme=white]/header:md:after:bg-black group-data-[theme=black]/header:md:after:bg-white group-data-[theme=primary]/header:md:after:bg-white">
                        {item.label}
                      </Link>
                    )
                  )}
                </li>
              ))}
            </ul>

            <ul className="flex gap-30 mt-40 md:hidden">
              {networks.map(({ href, label, icon }) => (
                <li key={label}>
                  <a href={href} className="text-18 font-medium text-white">
                    {socialIcons[icon]}
                    <span className="sr-only">{label}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </header>
  )
})

export default Header