import common from '../public/locales/fr/common.json'

export default function useTranslation() {
  const t = (key: string): string => {
    let clone = { ...common }

    key.split('.').forEach((k) => {
      // @ts-ignore
      clone = clone[k]
    })

    return clone.toString()
  }

  const o = (key: string): object => {
    let clone = { ...common }

    key.split('.').forEach((k) => {
      // @ts-ignore
      clone = clone[k]
    })

    return clone
  }

  return { t, o }
}