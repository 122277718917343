const Variant1 = () => (
  <svg className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1405 420" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1174.01 358.062V61.0625H1244.81V72.6825C1256.36 64.7234 1278.05 57.0625 1300.16 57.0625C1331.8 57.0625 1358 67.6485 1376.25 87.4384C1394.43 107.161 1405.01 135.265 1405.01 169.063C1405.01 202.86 1394.43 230.964 1376.25 250.687C1358 270.477 1331.8 281.062 1300.16 281.062C1279.05 281.062 1260.36 276.402 1244.81 267.442V358.062H1174.01ZM1260.69 130.197C1267.47 122.587 1277.69 118.062 1292.14 118.062C1306.59 118.062 1316.72 122.582 1323.43 130.167C1330.48 138.136 1336.01 150.873 1336.01 169.308C1336.01 187.569 1330.45 200.17 1323.38 208.059C1316.63 215.589 1306.47 220.062 1292.14 220.062C1277.69 220.062 1267.45 215.535 1260.67 207.964C1253.7 200.189 1249.01 187.685 1249.01 169.308C1249.01 150.654 1253.71 138.024 1260.69 130.197Z" />
    <path d="M1142 229.626V61.0625L1070.28 61.0625V225.613C1070.28 247.6 1065.82 263.986 1057.85 274.693C1050.16 285.015 1038.49 291.031 1021.4 291.031C1004.3 291.031 992.393 285.001 984.503 274.628C976.351 263.911 971.719 247.535 971.719 225.613V61.0625L900 61.0625V229.626C900 274.413 913.459 307.313 936.023 328.983C958.509 350.578 989.041 360.062 1021.4 360.062C1053.76 360.062 1084.11 350.574 1106.41 328.956C1128.76 307.274 1142 274.377 1142 229.626Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M899.941 358L821.204 358L803.298 338.064C781.745 352.237 754.949 360 725.956 360C695.26 360 668.829 353.021 649.883 338.432C630.647 323.62 620 301.62 620 273.774C620 255.718 624.678 240.055 635.281 226.049C644.288 214.149 657.271 203.845 674.317 194.213C659.87 174.624 650.619 155.341 650.619 131.541C650.619 109.785 659.097 90.8908 674.563 77.5425C689.93 64.2798 711.58 57 737.237 57C784.577 57 821.841 88.66 821.841 131.944C821.841 149.579 816.755 165.049 805.871 178.923C796.765 190.532 783.826 200.738 767.109 210.254L799.722 245.611C803.853 235.172 807.296 222.133 809.712 209.104H873.709C866.816 241.686 856.129 271.77 842.472 293.226L899.941 358ZM736.431 112.201C743.59 112.201 749.168 114.46 752.884 117.935C756.538 121.353 758.992 126.498 758.992 133.556C758.992 141.363 757.293 146.721 753.01 151.75C748.983 156.478 742.296 161.358 731.161 167.093C719.612 153.478 713.467 142.338 713.467 131.944C713.467 124.859 715.827 120.28 719.229 117.35C722.799 114.275 728.481 112.201 736.431 112.201ZM684.863 274.983C684.863 263.626 687.731 256.209 692.58 250.467C696.858 245.401 703.126 241.097 711.897 236.824L765.751 295.798C755.452 302.705 743.87 304.799 727.971 304.799C713.869 304.799 702.9 301.355 695.682 296.042C688.673 290.883 684.863 283.772 684.863 274.983Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M460.905 360.086C537.525 360.086 605 295.777 605 208.586C605 121.364 537.09 57.0859 460.5 57.0859C381.91 57.0859 316 121.364 316 208.586C316 295.823 381.925 360.086 460.905 360.086ZM460.905 290.598C439.611 290.598 422.178 282.1 409.254 267.894C396.274 253.628 388.441 233.131 388.441 208.586C388.441 184.04 396.275 163.537 409.213 149.267C422.096 135.057 439.428 126.574 460.5 126.574C481.794 126.574 499.226 135.072 512.15 149.277C525.13 163.544 532.964 184.04 532.964 208.586C532.964 233.157 525.215 253.657 512.334 267.915C499.514 282.105 482.193 290.598 460.905 290.598Z" />
    <path d="M145.102 124.523C164.341 124.523 178.28 131.506 185.418 138.12C191.275 143.547 198 152.547 203 173.055H276.186C273.206 154.989 266.559 138.003 263.507 131.583C259.634 123.342 253.446 112.354 244.16 101.342C225.453 79.1571 194.264 57.0547 145.102 57.0547C60.6939 57.0547 0 121.88 0 208.151C0 208.285 0.000140467 208.42 0.000420868 208.555C0.000140467 208.689 0 208.824 0 208.959C0 295.229 60.6939 360.055 145.102 360.055C194.264 360.055 225.453 337.952 244.16 315.767C253.446 304.755 259.634 293.767 263.507 285.527C265.448 281.399 266.819 277.935 267.716 275.468C268.165 274.234 268.495 273.247 268.719 272.549C268.831 272.199 268.917 271.921 268.978 271.721C269.008 271.62 269.032 271.539 269.05 271.478L269.072 271.401L269.08 271.374L269.083 271.363L269.085 271.354L272 261.075H197.072L195.062 266.12L195.061 266.124L195.058 266.13C195.051 266.148 195.035 266.184 195.012 266.238C194.953 266.369 194.846 266.604 194.686 266.927C194.365 267.575 193.835 268.571 193.066 269.801C191.523 272.268 189.051 275.623 185.418 278.99C178.28 285.603 164.341 292.587 145.102 292.587C120.993 292.587 103.404 284.029 90.9718 269.511C78.4604 254.9 70.8487 233.833 70.8487 208.555C70.8487 183.277 78.4604 162.21 90.9718 147.598C103.404 133.08 120.993 124.523 145.102 124.523Z" />
    <path d="M291 209.047L142 209.047L142 265.425H234.622V358.047H291L291 209.047Z" />
  </svg>
)

export default Variant1