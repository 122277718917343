import { useEffect, useState, useRef } from 'react'

import Variants from '@components/logos/Variants'

interface LogoProps {
  stopOnScroll?: boolean;
  timeout?: number;
}

const Logo = ({ stopOnScroll, timeout }: LogoProps) => {
  const [lastScroll, setLastScroll] = useState<Date | null>(null)
  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const logo = useRef<HTMLDivElement>(null)
  const intervalRef = useRef<number>(0)
  const [active, setActive] = useState<number>(0)
  const max = 11

  useEffect(() => {
    if (!stopOnScroll) return
    let ticking = false

    const updateScrollState = () => {
      setLastScroll(new Date())
      setIsScrolling(true)
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollState)
        ticking = true
      }
    }
  
    const didStop = setInterval(() => {
      if (lastScroll && new Date().getTime() - lastScroll.getTime() > 100) {
        setIsScrolling(false)
      }
    }, 100)

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
      clearInterval(didStop)
    }
  }, [lastScroll, isScrolling])

  useEffect(() => {
    // @ts-ignore
    intervalRef.current = setInterval(() => {
      if (stopOnScroll && !isScrolling) return
      setActive(active => (active + 1) % max)
    }, 200)

    if (timeout) {
      setTimeout(() => {
        clearInterval(intervalRef.current)
      }, timeout)
    }

    return () => clearInterval(intervalRef.current)
  }, [active, isScrolling])

  return (
    <div ref={logo} className="relative aspect-logo">
      <Variants number={active} />
    </div>
  )
}

export default Logo