import { useEffect, useState } from 'react'

import Variant1 from '@components/logos/Variant1'
import Variant2 from '@components/logos/Variant2'
import Variant3 from '@components/logos/Variant3'
import Variant4 from '@components/logos/Variant4'
import Variant5 from '@components/logos/Variant5'
import Variant6 from '@components/logos/Variant6'
import Variant7 from '@components/logos/Variant7'
import Variant8 from '@components/logos/Variant8'
import Variant9 from '@components/logos/Variant9'
import Variant10 from '@components/logos/Variant10'
import Variant11 from '@components/logos/Variant11'

interface VariantsProps {
  number: number;
}

const Variants = ({ number }: VariantsProps) => {
  const [tag, setTag] = useState(Variant1)

  const variants = [
    Variant1,
    Variant2,
    Variant3,
    Variant4,
    Variant5,
    Variant6,
    Variant7,
    Variant8,
    Variant9,
    Variant10,
    Variant11,
  ]

  useEffect(() => {
    setTag(variants[number])
  }, [number])

  return tag
}

export default Variants