const Variant4 = () => (
  <svg className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1405 420" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1174.01 358.062V61.0625H1244.81V72.6825C1256.36 64.7234 1278.05 57.0625 1300.16 57.0625C1331.8 57.0625 1358 67.6485 1376.25 87.4384C1394.43 107.161 1405.01 135.265 1405.01 169.063C1405.01 202.86 1394.43 230.964 1376.25 250.687C1358 270.477 1331.8 281.062 1300.16 281.062C1279.05 281.062 1260.36 276.402 1244.81 267.442V358.062H1174.01ZM1260.69 130.197C1267.47 122.587 1277.69 118.062 1292.14 118.062C1306.59 118.062 1316.72 122.582 1323.43 130.167C1330.48 138.136 1336.01 150.873 1336.01 169.308C1336.01 187.569 1330.45 200.17 1323.38 208.059C1316.63 215.589 1306.47 220.062 1292.14 220.062C1277.69 220.062 1267.45 215.535 1260.67 207.964C1253.7 200.189 1249.01 187.685 1249.01 169.308C1249.01 150.654 1253.71 138.024 1260.69 130.197Z" />
    <path d="M145.102 124.523C164.341 124.523 178.28 131.506 185.418 138.12C191.275 143.547 198 152.547 203 173.055H276.186C273.206 154.989 266.559 138.003 263.507 131.583C259.634 123.342 253.446 112.354 244.16 101.342C225.453 79.1571 194.264 57.0547 145.102 57.0547C60.6939 57.0547 0 121.88 0 208.151C0 208.285 0.000140467 208.42 0.000420868 208.555C0.000140467 208.689 0 208.824 0 208.959C0 295.229 60.6939 360.055 145.102 360.055C194.264 360.055 225.453 337.952 244.16 315.767C253.446 304.755 259.634 293.767 263.507 285.527C265.448 281.399 266.819 277.935 267.716 275.468C268.165 274.234 268.495 273.247 268.719 272.549C268.831 272.199 268.917 271.921 268.978 271.721C269.008 271.62 269.032 271.539 269.05 271.478L269.072 271.401L269.08 271.374L269.083 271.363L269.085 271.354L272 261.075H197.072L195.062 266.12L195.061 266.124L195.058 266.13C195.051 266.148 195.035 266.184 195.012 266.238C194.953 266.369 194.846 266.604 194.686 266.927C194.365 267.575 193.835 268.571 193.066 269.801C191.523 272.268 189.051 275.623 185.418 278.99C178.28 285.603 164.341 292.587 145.102 292.587C120.993 292.587 103.404 284.029 90.9718 269.511C78.4604 254.9 70.8487 233.833 70.8487 208.555C70.8487 183.277 78.4604 162.21 90.9718 147.598C103.404 133.08 120.993 124.523 145.102 124.523Z" />
    <path d="M291 209.047L142 209.047L142 265.425H234.622V358.047H291L291 209.047Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M876.104 60.2406L705.191 14.4446C682.738 8.42839 659.66 21.7528 653.644 44.2054L583.171 307.213C577.155 329.665 590.479 352.744 612.932 358.76L783.845 404.556C806.298 410.572 829.376 397.248 835.392 374.795L905.865 111.788C911.881 89.3353 898.557 66.2568 876.104 60.2406ZM682.682 51.9863C684.401 45.5713 690.995 41.7643 697.41 43.4832L868.323 89.2792C874.738 90.9982 878.545 97.592 876.826 104.007L806.354 367.014C804.635 373.429 798.041 377.236 791.626 375.517L620.713 329.721C614.298 328.002 610.491 321.408 612.21 314.993L682.682 51.9863ZM848.381 110.631L704.017 71.9489L651.774 266.923L796.138 305.605L848.381 110.631ZM710.695 335.74C719.859 338.195 729.279 332.757 731.734 323.593C734.19 314.428 728.752 305.008 719.587 302.553C710.423 300.097 701.003 305.536 698.547 314.7C696.092 323.864 701.53 333.284 710.695 335.74Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M902.332 260.204C907.096 289.732 918.955 312.55 936.023 328.942C958.509 350.537 989.041 360.021 1021.4 360.021C1053.76 360.021 1084.11 350.533 1106.41 328.915C1128.76 307.233 1142 274.336 1142 229.585V61.0215L1070.28 61.0215V225.572C1070.28 247.559 1065.82 263.945 1057.85 274.652C1050.16 284.974 1038.49 290.99 1021.4 290.99C1004.3 290.99 992.393 284.96 984.503 274.587C976.351 263.87 971.719 247.494 971.719 225.572V61.0215H931.187C941.656 78.3482 945.326 99.748 939.673 120.846L902.332 260.204ZM900 133.679L905.866 111.787C909.165 99.4753 906.648 86.9751 900 77.2032V133.679Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M590.963 142.908C567.19 90.9169 516.135 57 460.5 57C381.91 57 316 121.278 316 208.5C316 295.737 381.925 360 460.905 360C492.779 360 523.07 348.871 547.579 329.596C546.039 319.408 546.527 308.742 549.365 298.153L590.963 142.908ZM593.738 267.779C598.239 256.571 601.504 244.595 603.325 231.999L593.738 267.779ZM409.254 267.808C422.178 282.014 439.611 290.512 460.905 290.512C482.193 290.512 499.514 282.019 512.334 267.829C525.215 253.571 532.964 233.071 532.964 208.5C532.964 183.954 525.13 163.458 512.15 149.191C499.226 134.986 481.794 126.488 460.5 126.488C439.428 126.488 422.096 134.971 409.213 149.181C396.275 163.451 388.441 183.954 388.441 208.5C388.441 233.045 396.274 253.542 409.254 267.808Z" />
  </svg>
)

export default Variant4