const Variant5 = () => (
  <svg className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1405 420" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1174.01 358.062V61.0625H1244.81V72.6825C1256.36 64.7234 1278.05 57.0625 1300.16 57.0625C1331.8 57.0625 1358 67.6485 1376.25 87.4384C1394.43 107.161 1405.01 135.265 1405.01 169.063C1405.01 202.86 1394.43 230.964 1376.25 250.687C1358 270.477 1331.8 281.062 1300.16 281.062C1279.05 281.062 1260.36 276.402 1244.81 267.442V358.062H1174.01ZM1260.69 130.197C1267.47 122.587 1277.69 118.062 1292.14 118.062C1306.59 118.062 1316.72 122.582 1323.43 130.167C1330.48 138.136 1336.01 150.873 1336.01 169.308C1336.01 187.569 1330.45 200.17 1323.38 208.059C1316.63 215.589 1306.47 220.062 1292.14 220.062C1277.69 220.062 1267.45 215.535 1260.67 207.964C1253.7 200.189 1249.01 187.685 1249.01 169.308C1249.01 150.654 1253.71 138.024 1260.69 130.197Z" />
    <path d="M145.102 124.523C164.341 124.523 178.28 131.506 185.418 138.12C191.275 143.547 198 152.547 203 173.055H276.186C273.206 154.989 266.559 138.003 263.507 131.583C259.634 123.342 253.446 112.354 244.16 101.342C225.453 79.1571 194.264 57.0547 145.102 57.0547C60.6939 57.0547 0 121.88 0 208.151C0 208.285 0.000140467 208.42 0.000420868 208.555C0.000140467 208.689 0 208.824 0 208.959C0 295.229 60.6939 360.055 145.102 360.055C194.264 360.055 225.453 337.952 244.16 315.767C253.446 304.755 259.634 293.767 263.507 285.527C265.448 281.399 266.819 277.935 267.716 275.468C268.165 274.234 268.495 273.247 268.719 272.549C268.831 272.199 268.917 271.921 268.978 271.721C269.008 271.62 269.032 271.539 269.05 271.478L269.072 271.401L269.08 271.374L269.083 271.363L269.085 271.354L272 261.075H197.072L195.062 266.12L195.061 266.124L195.058 266.13C195.051 266.148 195.035 266.184 195.012 266.238C194.953 266.369 194.846 266.604 194.686 266.927C194.365 267.575 193.835 268.571 193.066 269.801C191.523 272.268 189.051 275.623 185.418 278.99C178.28 285.603 164.341 292.587 145.102 292.587C120.993 292.587 103.404 284.029 90.9718 269.511C78.4604 254.9 70.8487 233.833 70.8487 208.555C70.8487 183.277 78.4604 162.21 90.9718 147.598C103.404 133.08 120.993 124.523 145.102 124.523Z" />
    <path d="M291 209.047L142 209.047L142 265.425H234.622V358.047H291L291 209.047Z" />
    <path d="M911 198.179C911 167.584 887.383 150.259 865.612 150.259C826.128 150.259 804.726 187.489 787.382 226.931C771.884 208.131 757.124 187.858 744.577 167.584C777.788 154.314 806.202 136.62 806.202 104.92C806.202 74.6934 774.098 57 732.4 57C681.477 57 641.993 89.0693 641.993 136.62C641.993 151.365 648.266 170.901 658.599 192.65C618.008 209.237 580 235.04 580 285.909C580 326.456 616.532 359.263 663.396 359.263C701.404 359.263 727.972 347.836 750.113 317.978C776.312 343.044 802.512 360 823.176 360C868.933 360 909.893 311.343 909.893 265.266C909.893 256.42 907.31 254.208 905.465 254.208C903.989 254.208 902.882 256.051 902.882 257.157C902.882 259.737 904.727 261.949 904.727 266.741C904.727 279.642 893.288 294.755 877.42 294.755C854.173 294.755 822.069 267.478 791.441 232.091C808.047 195.967 823.914 173.85 828.711 173.85H829.08C829.08 175.325 822.069 184.54 822.069 196.336C822.069 222.876 842.365 241.307 868.564 241.307C893.288 241.307 911 220.664 911 198.179ZM758.231 145.099C758.231 152.471 751.958 158 741.625 163.161C719.116 126.668 703.618 91.6496 703.618 73.219C703.618 66.2153 709.891 62.1606 715.057 62.1606C734.983 62.1606 758.231 127.774 758.231 145.099ZM745.684 313.555C737.935 324.245 730.186 330.511 722.437 330.511C694.392 330.511 642.731 249.785 642.731 218.821C642.731 210.343 649.742 203.339 661.182 197.442C680.739 236.515 712.843 281.117 745.684 313.555Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M900 166.484V227.138C902.095 224.767 903.927 222.17 905.465 219.403C905.501 219.338 905.537 219.273 905.572 219.208C909.062 212.854 911 205.617 911 198.179C911 185.073 906.666 174.402 900 166.484ZM904.385 270.692C904.572 269.594 904.683 268.5 904.716 267.419C904.723 267.192 904.727 266.966 904.727 266.741C904.727 266.22 904.705 265.73 904.666 265.266C904.499 263.269 904.015 261.768 903.594 260.459L903.594 260.458C903.212 259.275 902.882 258.249 902.882 257.157C902.882 256.051 903.989 254.208 905.465 254.208C906.513 254.208 907.798 254.921 908.714 257.157C909.356 258.725 909.816 261.043 909.884 264.39C909.89 264.675 909.893 264.967 909.893 265.266C909.893 265.758 909.888 266.249 909.879 266.741C909.775 272.241 909.095 277.771 907.898 283.242C906.566 279.22 905.392 275.037 904.385 270.692ZM900 123.256V61.0176L971.719 61.0176V225.568C971.719 247.49 976.351 263.866 984.503 274.583C992.393 284.956 1004.3 290.987 1021.4 290.987C1038.49 290.987 1050.16 284.97 1057.85 274.648C1065.82 263.941 1070.28 247.555 1070.28 225.568V61.0176L1142 61.0176V229.581C1142 274.332 1128.76 307.229 1106.41 328.911C1084.11 350.529 1053.76 360.018 1021.4 360.018C989.041 360.018 958.509 350.533 936.023 328.938C934.083 327.075 932.21 325.129 930.407 323.099C939.443 305.417 944.893 285.577 944.893 265.266C944.893 258.284 944.013 248.958 939.317 240.07C938.515 238.551 937.571 237.015 936.488 235.501C942.631 224.087 946 211.218 946 198.179C946 161.826 925.687 135.627 900 123.256Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M602.905 182.377C572.049 203.141 545 235.154 545 285.909C545 300.091 547.787 313.324 552.803 325.329C527.442 347.245 495.083 360.041 460.905 360.041C381.925 360.041 316 295.778 316 208.541C316 121.319 381.91 57.041 460.5 57.041C529.205 57.041 590.925 108.766 602.905 182.377ZM580.435 293.53C592.917 274.21 601.3 251.49 604.031 226.471C589.482 241.347 580 260.501 580 285.909C580 288.483 580.147 291.025 580.435 293.53ZM409.254 267.85C422.178 282.055 439.611 290.553 460.905 290.553C482.193 290.553 499.514 282.06 512.334 267.87C525.215 253.612 532.964 233.112 532.964 208.541C532.964 183.995 525.13 163.499 512.15 149.232C499.226 135.027 481.794 126.529 460.5 126.529C439.428 126.529 422.096 135.012 409.213 149.222C396.275 163.492 388.441 183.995 388.441 208.541C388.441 233.086 396.274 253.583 409.254 267.85Z" />
  </svg>
)

export default Variant5