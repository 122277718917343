const Variant9 = () => (
  <svg className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1405 420" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1174.01 358.062V61.0625H1244.81V72.6825C1256.36 64.7234 1278.05 57.0625 1300.16 57.0625C1331.8 57.0625 1358 67.6485 1376.25 87.4384C1394.43 107.161 1405.01 135.265 1405.01 169.063C1405.01 202.86 1394.43 230.964 1376.25 250.687C1358 270.477 1331.8 281.062 1300.16 281.062C1279.05 281.062 1260.36 276.402 1244.81 267.442V358.062H1174.01ZM1260.69 130.197C1267.47 122.587 1277.69 118.062 1292.14 118.062C1306.59 118.062 1316.72 122.582 1323.43 130.167C1330.48 138.136 1336.01 150.873 1336.01 169.308C1336.01 187.569 1330.45 200.17 1323.38 208.059C1316.63 215.589 1306.47 220.062 1292.14 220.062C1277.69 220.062 1267.45 215.535 1260.67 207.964C1253.7 200.189 1249.01 187.685 1249.01 169.308C1249.01 150.654 1253.71 138.024 1260.69 130.197Z" />
    <path d="M145.102 124.523C164.341 124.523 178.28 131.506 185.418 138.12C191.275 143.547 198 152.547 203 173.055H276.186C273.206 154.989 266.559 138.003 263.507 131.583C259.634 123.342 253.446 112.354 244.16 101.342C225.453 79.1571 194.264 57.0547 145.102 57.0547C60.6939 57.0547 0 121.88 0 208.151C0 208.285 0.000140467 208.42 0.000420868 208.555C0.000140467 208.689 0 208.824 0 208.959C0 295.229 60.6939 360.055 145.102 360.055C194.264 360.055 225.453 337.952 244.16 315.767C253.446 304.755 259.634 293.767 263.507 285.527C265.448 281.399 266.819 277.935 267.716 275.468C268.165 274.234 268.495 273.247 268.719 272.549C268.831 272.199 268.917 271.921 268.978 271.721C269.008 271.62 269.032 271.539 269.05 271.478L269.072 271.401L269.08 271.374L269.083 271.363L269.085 271.354L272 261.075H197.072L195.062 266.12L195.061 266.124L195.058 266.13C195.051 266.148 195.035 266.184 195.012 266.238C194.953 266.369 194.846 266.604 194.686 266.927C194.365 267.575 193.835 268.571 193.066 269.801C191.523 272.268 189.051 275.623 185.418 278.99C178.28 285.603 164.341 292.587 145.102 292.587C120.993 292.587 103.404 284.029 90.9718 269.511C78.4604 254.9 70.8487 233.833 70.8487 208.555C70.8487 183.277 78.4604 162.21 90.9718 147.598C103.404 133.08 120.993 124.523 145.102 124.523Z" />
    <path d="M291 209.047L142 209.047L142 265.425H234.622V358.047H291L291 209.047Z" />
    <path d="M669.877 174.261C664.642 170.084 660.081 163.595 656.193 154.792C652.454 145.99 650.585 136.741 650.585 127.044C650.585 106.008 658.062 89.0753 673.017 76.2452C687.973 63.4151 709.359 57 737.175 57C761.403 57 780.546 64.161 794.603 78.483C806.867 90.8656 812.998 106.157 812.998 124.358C812.998 136.741 808.362 144.2 799.09 146.736L755.346 159.044C719.154 169.189 701.058 189.404 701.058 219.689C701.058 235.801 706.667 249.303 717.883 260.193C728.053 270.338 740.241 275.411 754.449 275.411C759.833 275.411 764.543 274.366 768.581 272.278C772.619 270.189 774.638 267.205 774.638 263.326C774.638 261.238 773.89 259.597 772.395 258.403C771.049 257.21 769.254 256.613 767.011 256.613C761.627 256.613 757.514 259.522 754.673 265.34C742.41 264.445 732.315 259.671 724.389 251.018C716.612 242.216 712.724 231.773 712.724 219.689C712.724 207.605 716.462 197.162 723.94 188.36C731.567 179.558 743.307 172.844 759.16 168.219L802.904 155.688C816.363 151.809 823.093 142.932 823.093 129.058C831.468 125.925 839.619 124.358 847.545 124.358C860.855 124.358 872.296 129.132 881.867 138.68C891.289 148.527 896 160.611 896 174.933C896 189.255 891.962 200.668 883.886 209.171C875.81 217.526 866.239 223.344 855.172 226.626L851.583 227.745V228.864C851.583 233.041 853.901 235.13 858.537 235.13C860.481 235.13 862.575 234.459 864.818 233.116C871.399 240.575 874.689 250.496 874.689 262.879C874.689 275.261 871.922 287.42 866.389 299.355C861.005 311.141 853.078 321.435 842.61 330.237C819.28 350.079 787.649 360 747.719 360C707.788 360 675.41 350.601 650.585 331.804C624.862 312.857 612 287.793 612 256.613C612 214.84 631.292 187.39 669.877 174.261Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M602.271 178.703C588.882 107.028 528.069 57 460.5 57C381.91 57 316 121.278 316 208.5C316 295.737 381.925 360 460.905 360C509.989 360 555.319 333.609 581.562 291.717C578.54 280.666 577 268.942 577 256.613C577 230.304 583.128 205.496 597.834 184.571C599.254 182.551 600.733 180.595 602.271 178.703ZM409.254 267.808C422.178 282.014 439.611 290.512 460.905 290.512C482.193 290.512 499.514 282.019 512.334 267.829C525.215 253.571 532.964 233.071 532.964 208.5C532.964 183.954 525.13 163.458 512.15 149.191C499.226 134.986 481.794 126.488 460.5 126.488C439.428 126.488 422.096 134.971 409.213 149.181C396.275 163.451 388.441 183.954 388.441 208.5C388.441 233.045 396.274 253.542 409.254 267.808Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M907.907 283.272C914.089 301.918 923.698 317.105 936.023 328.942C958.509 350.537 989.041 360.021 1021.4 360.021C1053.76 360.021 1084.11 350.533 1106.41 328.915C1128.76 307.233 1142 274.336 1142 229.585V61.0215L1070.28 61.0215V225.572C1070.28 247.559 1065.82 263.945 1057.85 274.652C1050.16 284.974 1038.49 290.99 1021.4 290.99C1004.3 290.99 992.393 284.96 984.503 274.587C976.351 263.87 971.719 247.494 971.719 225.572V61.0215L900 61.0215V107.944C902.267 109.798 904.464 111.785 906.586 113.901L906.874 114.189L907.155 114.483C923.152 131.201 931 152.102 931 174.933C931 196.129 924.824 216.89 909.265 233.273L909.159 233.386L909.051 233.497C908.023 234.56 906.977 235.598 905.914 236.609C908.546 245.135 909.689 254.02 909.689 262.879C909.689 269.776 909.085 276.579 907.907 283.272Z" />
  </svg>
)

export default Variant9