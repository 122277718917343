const Variant6 = () => (
  <svg className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1405 420" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1174.01 358.062V61.0625H1244.81V72.6825C1256.36 64.7234 1278.05 57.0625 1300.16 57.0625C1331.8 57.0625 1358 67.6485 1376.25 87.4384C1394.43 107.161 1405.01 135.265 1405.01 169.063C1405.01 202.86 1394.43 230.964 1376.25 250.687C1358 270.477 1331.8 281.062 1300.16 281.062C1279.05 281.062 1260.36 276.402 1244.81 267.442V358.062H1174.01ZM1260.69 130.197C1267.47 122.587 1277.69 118.062 1292.14 118.062C1306.59 118.062 1316.72 122.582 1323.43 130.167C1330.48 138.136 1336.01 150.873 1336.01 169.308C1336.01 187.569 1330.45 200.17 1323.38 208.059C1316.63 215.589 1306.47 220.062 1292.14 220.062C1277.69 220.062 1267.45 215.535 1260.67 207.964C1253.7 200.189 1249.01 187.685 1249.01 169.308C1249.01 150.654 1253.71 138.024 1260.69 130.197Z" />
    <path d="M145.102 124.523C164.341 124.523 178.28 131.506 185.418 138.12C191.275 143.547 198 152.547 203 173.055H276.186C273.206 154.989 266.559 138.003 263.507 131.583C259.634 123.342 253.446 112.354 244.16 101.342C225.453 79.1571 194.264 57.0547 145.102 57.0547C60.6939 57.0547 0 121.88 0 208.151C0 208.285 0.000140467 208.42 0.000420868 208.555C0.000140467 208.689 0 208.824 0 208.959C0 295.229 60.6939 360.055 145.102 360.055C194.264 360.055 225.453 337.952 244.16 315.767C253.446 304.755 259.634 293.767 263.507 285.527C265.448 281.399 266.819 277.935 267.716 275.468C268.165 274.234 268.495 273.247 268.719 272.549C268.831 272.199 268.917 271.921 268.978 271.721C269.008 271.62 269.032 271.539 269.05 271.478L269.072 271.401L269.08 271.374L269.083 271.363L269.085 271.354L272 261.075H197.072L195.062 266.12L195.061 266.124L195.058 266.13C195.051 266.148 195.035 266.184 195.012 266.238C194.953 266.369 194.846 266.604 194.686 266.927C194.365 267.575 193.835 268.571 193.066 269.801C191.523 272.268 189.051 275.623 185.418 278.99C178.28 285.603 164.341 292.587 145.102 292.587C120.993 292.587 103.404 284.029 90.9718 269.511C78.4604 254.9 70.8487 233.833 70.8487 208.555C70.8487 183.277 78.4604 162.21 90.9718 147.598C103.404 133.08 120.993 124.523 145.102 124.523Z" />
    <path d="M291 209.047L142 209.047L142 265.425H234.622V358.047H291L291 209.047Z" />
    <path d="M905 246.375H829.25V284.25H905V360H829.25V322.125H791.375V360H639.875V322.125H602V208.5H639.875V170.625H602V94.875H639.875V57H791.375V94.875H829.25V170.625H905V246.375ZM677.75 132.75V170.625H753.5V132.75H677.75ZM677.75 284.25H753.5V246.375H677.75V284.25ZM827.735 170.625H791.375V208.5H827.735V170.625Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M900 135.625H940V332.564C962.043 351.601 990.911 360.021 1021.4 360.021C1053.76 360.021 1084.11 350.533 1106.41 328.915C1128.76 307.233 1142 274.336 1142 229.585V61.0215L1070.28 61.0215V225.572C1070.28 247.559 1065.82 263.945 1057.85 274.652C1050.16 284.974 1038.49 290.99 1021.4 290.99C1004.3 290.99 992.393 284.96 984.503 274.587C976.351 263.87 971.719 247.494 971.719 225.572V61.0215L900 61.0215V135.625ZM900.667 246.375C900.225 240.976 900 235.38 900 229.585V170.625H905V246.375H900.667Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M605 208.5C605 208.515 605 208.53 605 208.545C605 219.354 603.963 229.812 602 239.839V208.5H605ZM567 106.018V311.276C540.18 341.682 501.848 360.045 460.905 360.045C381.925 360.045 316 295.782 316 208.545C316 121.323 381.91 57.0449 460.5 57.0449C501.525 57.0449 540.059 75.4868 567 106.018ZM409.254 267.853C422.178 282.059 439.611 290.557 460.905 290.557C482.193 290.557 499.514 282.064 512.334 267.874C525.215 253.616 532.964 233.116 532.964 208.545C532.964 183.999 525.13 163.503 512.15 149.236C499.226 135.031 481.794 126.533 460.5 126.533C439.428 126.533 422.096 135.016 409.213 149.226C396.275 163.496 388.441 183.999 388.441 208.545C388.441 233.09 396.274 253.587 409.254 267.853Z" />
  </svg>
)

export default Variant6