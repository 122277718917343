import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => (
  <div className="px-25 lg:px-container">{children}</div>
)

export default Container
